<template>
  <div class="container">
    <div class="course-head">
      <el-page-header
        :title="$t('courses.back')"
        @back="routerPush({ name: 'Home' })"
        :content="$t('courses.my_courses')"
      >
      </el-page-header>
    </div>
    <div class="cards">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('courses.online_courses')" name="onlineCourses">
          <el-row v-if="courses.length > 0">
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
              :xl="6"
              v-for="course in courses"
              :key="course.course_id"
            >
              <a class="card" @click="startCourse(course.course_id)">
                <div class="card-image">
                  <img :src="course.cover_photo" />
                </div>
                <div class="card-details">
                  <h3 class="details-name">{{ course.course_name }}</h3>
                  <div class="details-instructor">
                    <span>Instructor: {{ course.author }}</span>
                  </div>
                  <div class="details-progress">
                    <el-progress
                      :percentage="
                        progress(course.completed, course.incompleted)
                      "
                    ></el-progress>
                  </div>
                  <div class="details-btn">
                    <el-button
                      type="primary"
                      size="mini"
                      v-if="course.incompleted > 0 && course.completed > 0"
                      >{{ $t("courses.continue") }}</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      v-else-if="course.completed == 0"
                    >
                      {{ $t("courses.start") }}
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      v-else-if="course.incompleted == 0"
                      >{{ $t("courses.completed") }}</el-button
                    >
                  </div>
                </div>
              </a>
            </el-col>
          </el-row>
          <div v-else>
            <el-alert
              :title="$t('courses.no_courses')"
              type="info"
              :closable="false"
              center
              show-icon
            >
            </el-alert>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane
          :label="$t('courses.others')"
          name="otherCourses"
          disabled=""
        >
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";
// 初始化數據
export default {
  name: "learning",

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      courses: {},
      activeName: "onlineCourses"
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"])
  },
  watch: {
    langValue() {
      this.fetchData();
    }
  },

  async mounted() {},

  methods: {
    progress(completed, incompleted) {
      let total = completed + incompleted;
      return Math.round((completed / total) * 100);
    },
    checkCourse() {
      let check = true;
      //TODO
      let isActive = true; // 检查是否在维护
      let canUse = true; //检查是否过期
      if (isActive) {
        if (!canUse) {
          check = false;
          this.$message({
            message: this.$t("courses.course_has_expired"),
            type: "warning"
          });
        }
      } else {
        check = false;
        this.$message({
          message: this.$t("courses.course_maintenance"),
          type: "warning"
        });
      }
      return check;
    },
    startCourse(course_id) {
      if (this.checkCourse()) {
        this.routerPush({
          name: "studentCourse",
          query: { id: course_id }
        });
      }
    },
    async fetchData() {
      const data = await onlineCourseApi.studentManagement({
        lang: this.langValue
      });
      if (data.status_code !== 0) {
        this.courses = data;
      }
    }
  }
};
</script>

<style scoped>
.course-head {
  margin-top: 20px;
}

.card {
  position: relative;
  color: #29303b;
  display: block;
  background-color: transparent;
  font-weight: 400;
  text-decoration: none;
  padding: 0;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.card:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  background: #000;
  height: 200px;
  line-height: 150px;
  text-align: center;
}

.card-image img {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.card-details {
  padding: 0 10px 10px;
}

.card-details span {
  font-size: 12px;
  color: #686f7a;
}

.details-name {
  height: 3.4em;
  overflow: hidden;
  margin: 10px 0 0 0;
}
.details-btn {
  margin: 10px 0 0 0;
}
</style>
